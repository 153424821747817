/**
 * MacOS X like theme for elFinder.
 * Required jquery ui "smoothness" theme.
 *
 * @author Dmitry (dio) Levashov
 **/

/* input textarea */
.elfinder input,
.elfinder textarea
{
	color            : #000000;
	background-color : #FFFFFF;
}

/* dialogs */
.std42-dialog, .std42-dialog .ui-widget-content
{ background-color : #EDEDED; background-image : none; background-clip : content-box; }

/* navbar */
.elfinder .elfinder-navbar
{ background : #DDE4EB; }

.elfinder-navbar .ui-state-hover
{ color : #000000; background-color : #EDF1F4; border-color : #BDCBD8; }

.elfinder-navbar .ui-state-active
{ background : #3875D7; border-color : #3875D7; color : #FFFFFF; }

.elfinder-navbar .elfinder-droppable-active
{ background : #A7C6E5 !important; }

/* disabled elfinder */
.elfinder-disabled .elfinder-navbar .ui-state-active
{ background : #DADADA; border-color : #AAAAAA; color : #FFFFFF; }

/* current directory */
/* selected file in "icons" view */
.elfinder-cwd-view-icons .elfinder-cwd-file .ui-state-hover
{ background : #CCCCCC; }

/* list view*/
.elfinder-cwd table thead td.ui-state-hover
{ background : #DDDDDD; }

.elfinder-cwd table tr:nth-child(odd)
{ background-color : #EDF3FE; }

.elfinder-cwd table tr
{
	border     : 1px solid transparent;
	border-top : 1px solid #FFFFFF;
}

.elfinder-cwd .elfinder-droppable-active td
{ background : #A7C6E5 !important; }

/* common selected background/color */
.elfinder-cwd-view-icons .elfinder-cwd-file .elfinder-cwd-filename.ui-state-hover,
.elfinder-cwd table td.ui-state-hover,
.elfinder-button-menu .ui-state-hover
{ background : #3875D7; color : #FFFFFF; }

/* disabled elfinder */
.elfinder-disabled .elfinder-cwd-view-icons .elfinder-cwd-file .elfinder-cwd-filename.ui-state-hover,
.elfinder-disabled .elfinder-cwd table td.ui-state-hover
{ background : #DADADA; }

/* statusbar */
.elfinder .elfinder-statusbar
{ color : #555555; }

.elfinder .elfinder-statusbar a
{ text-decoration : none; color : #555555; }

.std42-dialog .elfinder-help, .std42-dialog .elfinder-help .ui-widget-content
{ background : #FFFFFF; }

/* contextmenu */
.elfinder-contextmenu .ui-state-hover
{ background : #3875D7; color : #FFFFFF; }

.elfinder-contextmenu .ui-state-hover .elfinder-contextmenu-arrow
{ background-image : url('/lib/file_manager/img/arrows-active.png'); }

