.dropzone {
    width         : 100%;
    border        : 2px dashed #0087F7;
    border-radius : 5px;
    background    : white;
}

.dropzone .dz-message {
    font-weight : 400;
}

.dropzone .dz-message .note {
    font-size   : 0.8em;
    font-weight : 200;
    display     : block;
    margin-top  : 1.4rem;
}