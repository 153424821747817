@charset "utf-8";

html, body
{ font-family : arial; }

.dropzone
{ background-color : #EEEEEE; text-align : center; position : relative; border : 1px solid #DDDDDD; display : inline-block; }

.dropzone:after
{ content : 'Drop your image here or click to add one!'!important; font-size : 16px; color : #BBBBBB; position : absolute; left : 0; width : 100%; text-align : center; z-index : 0; }

.dropzone:before
{ content : ''; font-family : "Glyphicons Halflings"; font-size : 60px; color : #DBDBDB; position : absolute; top : 40%; left : 0; width : 100%; text-align : center; z-index : 0; }

.dropzone.loading:after
{ content : 'Please wait, image is loading'; }

.dropzone.loading:before
{ content : ''; }

.dropzone.done:after
{ content : ''; }

.dropzone.done:before
{ content : ''; }

/* not an image */
.dropzone.notAnImage
{ background-color : #F2DEDE; border-color : #EBCCD1; }

.dropzone.notAnImage:after
{ content : 'The selected file is not an image!'; color : #A94442; }

.dropzone.notAnImage:before
{ content : ''; color : #EBCCD1; }

.dropzone.alert-danger
{ background-color : #F2DEDE; }

.dropzone.alert-danger:after
{ content : ''; }

.dropzone.smalltext:before,
.dropzone.smalltext:after
{ font-size : 20px; }

.dropzone > span
{ font-size : 30px; color : #BBBBBB; position : absolute; top : 35%; left : 0; width : 100%; text-align : center; z-index : 0; }

.dropzone > span.loader
{ display : none; }

.dropzone > input[type=file]
{ position : absolute; top : 0; left : 0; bottom : 0; right : 0; width : 100%; opacity : 0; cursor : pointer; z-index : 2; height : 100% /* IE HACK*/ }

.dropzone > input[type=text]
{ display : none; }

.dropzone .progress
{ bottom : 10px; left : 10px; right : 10px; display : none; }

.dropzone .cropWrapper
{ overflow : hidden; position : absolute; top : 0; bottom : 0; left : 0; right : 0; z-index : 10; background-color : #EEEEEE; text-align : left; }

/*.dropzone canvas.final			{ background-color: #eeeeee;}*/

.dropzone img
{ z-index : 5; position : relative; }

.dropzone img.ghost
{ opacity : .2; z-index : auto; float : left /* HACK for not scrolling*/; }

.dropzone img.main
{ cursor : move; }

.dropzone .final img.main
{ cursor : auto; }

.dropzone img.preview
{ width : 100%; }

.dropzone .tools
{ position : absolute; top : 10px; right : 10px; z-index : 999; display : inline-block; }

.dropzone .tools > *
{ margin : 0 0 0 5px; }

.dropzone.smalltools .tools .btn
{ padding : 1px 4px; font-size : 12px; }

.dropzone .download
{ position : absolute; bottom : 10px; left : 10px; z-index : 999; display : inline-block; }

.dropzone .download > *
{ margin : 0 0 0 5px; }

